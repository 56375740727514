const customSelect = document.querySelector(".customSelect");
const selectButton = document.querySelector(".selectButton");
const selectedValue = document.querySelector(".selectedValue");
const selectRequiredInput = document.querySelector(".selectRequiredInput");
const optionsList = document.querySelectorAll(".selectDropdown li");
const companyName = document.querySelector(".companyName");

const toggleCompanyNameDisplay = (idOfSelectedOption) => {
  if (idOfSelectedOption === "freelancer" || idOfSelectedOption === "realEstateBuyer") {
    companyName.classList.add("hide");
    companyName.children[1].removeAttribute("required");
  } else {
    companyName.classList.remove("hide");
    companyName.children[1].setAttribute("required", "true");
  }
}

selectButton.addEventListener("click", () => {
  customSelect.classList.toggle("active");
  selectButton.setAttribute("aria-expanded", selectButton.getAttribute("aria-expanded") === "true" ? "false" : "true");
}); 

optionsList.forEach((option) => {
  function handler(e) {
    if (e.type === "click" && e.clientX !== 0 && e.clientY !== 0) {
      selectedValue.textContent = this.children[1].textContent;
      selectRequiredInput.value = this.children[0].id;
      customSelect.classList.remove("active");
      toggleCompanyNameDisplay(this.children[0].id)
    }
  }

  option.addEventListener("click", handler);
});
